// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f1f3f8;
$gray-200: #e3e8ec;
$gray-300: #bcc5d0;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #94a0b2;
$gray-700: #3e515b;
$gray-800: #444d58;
// $gray-900:                        #151b1e;
$gray-900: #6a6c6f;
$black: #000 !default;

// $red: #f86c6b;
// $orange: #f8cb00;
// $green: #4dbd74;
// $teal: #20c997 !default;
// $cyan: #63c2de;
// $blue: #20a8d8;
$blue: #265bb8;
// $blue: #1f466d;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f;
$orange: #f0ad4e;
$yellow: #ffc107 !default;
// $green: #2dbc3f;
$green: #33ae52;
$teal: #20c997 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$android: #aece47 !default;
$ios: #007aff !default;
$navy: #2862d6 !default;
$teal: #4e90a1 !default;
$white: #ffffff !default;
$maroon: maroon !default;
$quokka-primary: #487f87 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  maroon: $maroon,
);

$theme-colors: (
  primary: $quokka-primary,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  ios: $ios,
  android: $android,
  pink: $pink,
  purple: $purple,
  navy: $navy,
  teal: $teal,
  critical: $maroon,
);
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-transitions: true;
$enable-rounded: false;
// Body
//
// Settings for the `<body>` element.
$body-bg: #eff3f8;
// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 0.875rem;
// Breadcrumbs
$breadcrumb-bg: #fff;
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 1.25rem;
// Cards
$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;
// Buttons
$btn-secondary-border: $gray-300;
// Progress bars
$progress-bg: $gray-100;
// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
