// Slide down on enter, back up on exit
.slide-down-appear {
  opacity: 0.01;
}
.slide-down-appear.slide-down-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.slide-down-enter {
  opacity: 0.01;
}
.slide-down-enter.slide-down-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.slide-down-leave {
  opacity: 1;
}
.slide-down-leave.slide-down-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
