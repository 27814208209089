.new-user-password {
  $mobile-width: 500px;

  display: flex;
  flex-direction: column;
  height: 100vh;
  &__header-row {
    padding-top: 10px;
    padding-bottom: 11px;
    top: 3px;
    justify-content: center;
  }
  &__main {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
  }
  &__title {
    margin: 0;
    text-align: center;
  }
  &__card {
    max-width: 490px;
    min-width: 400px;
    min-height: 350px;
    @media only screen and (max-width: $mobile-width) {
      margin: 0;
    }
  }
  &__card-body {
    padding: 32px 42px 38px 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: $mobile-width) {
      padding: 32px 30px 38px 30px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__fieldset {
      justify-content: space-between;
    }
    &__label-group {
      display: flex;
      justify-content: space-between;
    }
    &__label {
      font-size: 1rem;
    }
    &__input {
      font-size: 1.2rem;
    }
  }
  &__submit-button {
    font-size: 1.2rem;
  }
  &__attribution {
    text-align: center;
    width: 100%;
  }

  // https://github.com/gladchinda/password-strength-react-demo
  $primary: #007bff;
  $strength-colors: (darkred, orangered, orange, yellowgreen, green);
  $strength-gap: 6px;
  .strength-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 3px 0;
    border-radius: 2px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .strength-meter {
    &:before,
    &:after {
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 6px 0;
      position: absolute;
      width: calc(20% + 6px);
      z-index: 10;
    }

    &::after {
      right: calc(20% - 3px);
    }
    &::before {
      left: calc(20% - 3px);
    }
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;

    @for $i from 1 through 5 {
      &[data-strength='#{$i - 1}'] {
        width: (20% * $i);
        background: nth($strength-colors, $i);
      }
    }
  }

  .form-hint {
    font-size: 0.6rem;
    line-height: 1.4;
    color: #999;
    margin-top: 3px;
    text-align: left;
    &.error {
      color: #c00;
      font-size: 0.8rem;
    }
  }

  .password-help-tooltip {
    .tooltip-inner {
      max-width: 280px;
    }
  }
}
