// Core Admin Variables

$enable-sidebar-nav-rounded: false !default;

$border-color: $gray-200 !default;
$layout-transition-speed: 0.25s !default;

// Social Colors

$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$google-plus: #d34836 !default;
$flickr: #ff0084 !default;
$tumblr: #32506d !default;
$xing: #026466 !default;
$github: #4183c4 !default;
$html5: #e34f26 !default;
$openid: #f78c40 !default;
$stack-overflow: #fe7a15 !default;
$youtube: #b00 !default;
$css3: #0170ba !default;
$dribbble: #ea4c89 !default;
$google-plus: #bb4b39 !default;
$instagram: #517fa4 !default;
$pinterest: #cb2027 !default;
$vk: #45668e !default;
$yahoo: #400191 !default;
$behance: #1769ff !default;
$dropbox: #007ee5 !default;
$reddit: #ff4500 !default;
$spotify: #7ab800 !default;
$vine: #00bf8f !default;
$foursquare: #1073af !default;
$vimeo: #aad450 !default;

// Custom color
$dark-cyan: #244c5a;
$salmon: #ffa38b;

// Navbar

$navbar-height: 55px !default;
$navbar-bg: #fff !default;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;
$navbar-brand-width: 155px !default;
$navbar-brand-bg: #fff !default;
$navbar-brand-logo: url('../../img/Quokka_Banner_V2.svg') !default;
$navbar-brand-logo-size: 70px auto !default;
$navbar-brand-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

$navbar-color: $gray-600 !default;
$navbar-hover-color: $gray-800 !default;
$navbar-active-color: $gray-800 !default;
$navbar-disabled-color: $gray-300 !default;

// Sidebar

$sidebar-width: 200px !default;
$sidebar-padding: 0 !default;
$sidebar-minimized-width: 50px !default;
$sidebar-minimized-height: $sidebar-minimized-width !default;
$sidebar-compact-width: 52px !default;
$sidebar-compact-height: $sidebar-compact-width !default;
$sidebar-color: #fff !default;
$sidebar-bg: $dark-cyan !default;
$sidebar-header-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-footer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-borders: none !default;
$mobile-sidebar-width: 220px !default;

// Sidebar Navigation

$sidebar-nav-color: #fff !default;
$sidebar-nav-title-padding-y: 0.75rem !default;
$sidebar-nav-title-padding-x: 1rem !default;
$sidebar-nav-title-color: $gray-200 !default;
$sidebar-nav-link-padding-y: 0.75rem !default;
$sidebar-nav-link-padding-x: 1rem !default;
$sidebar-nav-link-color: #fff !default;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-icon-color: $text-muted !default;
$sidebar-nav-link-borders: 0 !default;

$sidebar-nav-link-hover-color: $salmon !default;
$sidebar-nav-link-hover-bg: $dark-cyan !default;
$sidebar-nav-link-hover-icon-color: $salmon !default;
$sidebar-nav-link-hover-borders: 0 !default;

$sidebar-nav-link-active-color: $salmon !default;
$sidebar-nav-link-active-bg: $dark-cyan !default;
$sidebar-nav-link-active-icon-color: $salmon !default;
$sidebar-nav-link-active-borders: 0 !default;

$sidebar-nav-dropdown-color: #fff !default;
$sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-nav-dropdown-borders: 0 !default;

// Top Navigation

$top-nav-bg: #fff !default;
$top-nav-color: $body-color !default;
$top-nav-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;
$top-nav-ul-borders: (
  all: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

$top-nav-hover-color: #fff !default;
$top-nav-hover-bg: theme-color('primary') !default;
$top-nav-active-color: #fff !default;
$top-nav-active-bg: theme-color('primary') !default;
$top-nav-height: $navbar-height - 15px !default;

// Breadcrumb

$breadcrumb-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

// Aside

$aside-menu-width: 250px !default;
$aside-menu-color: $gray-800 !default;
$aside-menu-bg: #fff !default;
$aside-menu-borders: (
  left: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

$aside-menu-nav-padding-y: 0.75rem !default;
$aside-menu-nav-padding-x: 1rem !default;

// Footer

$footer-height: 50px !default;
$footer-bg: $gray-100 !default;
$footer-color: $body-color !default;
$footer-borders: (
  top: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

// Cards

$card-icon-bg: transparent !default;
$card-icon-color: $body-color !default;
