// Encapsulate all styles into the analysis reports
.analysis-report {
  // General report area
  .report-tab-content {
    background: none;
    border: none;

    .tab-pane {
      padding: 0px;
    }
  }

  // Header
  .report-header-card {
    margin-bottom: 0;
  }

  .report-header-text {
    margin: 3px;
  }

  .report-header-app-section {
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .report-header-app-inner {
    display: inline-block;
    vertical-align: middle;
  }

  .report-header-app-name {
    font-size: 1.3rem;
  }

  .report-header-app-info {
    font-size: 1.1rem;
  }

  .report-header-threat-score {
    border-radius: 5px;
    color: #fff;
    padding: 0.5em 0.5em 0.55em 0.5em;
    font-size: 1.5rem;
  }

  // Report tabs
  .tab-card-block {
    padding: 0px;
  }

  .report-tab-row {
    display: flex;
    width: 100%;
  }

  .report-tab-android {
    cursor: pointer;
    text-align: center;
    padding: 10px 0 10px 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      position: absolute;
      left: 0%;
      top: 0%;
      content: "";
      height: 100%;
      transition: all 0.3s linear;
      width: 100%;
      border-bottom: 2px solid $android;
      transform: scaleX(0);
    }
  }
  .report-tab-ios {
    cursor: pointer;
    text-align: center;
    padding: 10px 0 10px 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      position: absolute;
      left: 0%;
      top: 0%;
      content: "";
      height: 100%;
      transition: all 0.3s linear;
      width: 100%;
      border-bottom: 2px solid $ios;
      transform: scaleX(0);
    }
  }

  .report-active-tab {
    background-color: $gray-600;
    color: #fff;
    transition: all 0.3s linear;

    &:after {
      transform: scale(1);
    }
  }

  // Cards
  .card-accent-android {
    border-top-width: 2px;
    border-top-color: #a5d11c;
  }

  .card-header {
    font-weight: bold;
    padding: 0.45em 1.25em;
  }

  // App info
  .app-info-section {
    .app-info-title {
      margin-bottom: 0.2rem;
      font-style: italic;
    }
    .app-info-value {
      font-weight: bold;
    }
  }

  // Market info
  .market-info-section {
    .market-info-title {
      margin-bottom: 0.2rem;
      font-style: italic;
    }
    .market-info-value {
      font-weight: bold;
    }
  }

  // Cert info
  .cert-info-section {
    .cert-info-title {
      margin-bottom: 0.2rem;
      font-style: italic;
    }
    .cert-info-value {
      font-weight: bold;
    }
  }

  // Harviewer
  .entry-details-row {
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    border-top: 1px solid #eaeaea;
  }

  .entry-details-tab {
    padding: 2px 6px;
    cursor: pointer;
    border-top: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    border-radius: 3px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .active-entry-details-tab {
    background-color: $gray-600;
    color: $white !important;
  }
}
