.new-user-password {
  &__confirmation {
    &__wrapper {
      margin: 1.5rem 4rem;
      &__redirect {
        text-align: center;
      }
    }
  }
}
