@font-face {
  font-family: 'NunitoSans';
  src: url('../webfonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'NunitoSans';
  src: url('../webfonts/NunitoSans-Bold.ttf') format('truetype');
}

* {
  font-family: 'NunitoSans';
}

// Change cards to have a box-shadow
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

$navbar-brand-width: 220px;
.navbar-brand {
  height: 58px !important;
}

// Helper class to make something have a cursor: pointer;
.pointer {
  cursor: pointer;
}

// Custom text colors for each theme color
@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $color;
  }
}

.disabled-uncontrolled-button-dropdown button {
  background-color: lightgray !important;
  border: none;
  color: white;
}

// Custom background colors for each theme color and grays
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}
@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: $value;
  }
}

// ---------------------------------
// Custom colors
// ---------------------------------
$body-color: #6a6c6f !default;

.btn-secondary {
  color: #fff;
}
.btn-secondary:hover {
  color: #fff;
}

.btn-warning {
  color: $white;
}
.btn-warning:hover {
  color: $white;
}

.btn.disabled {
  opacity: 0.3;
}
.text-disabled {
  opacity: 0.3;
}

// -----------------------------------------------------------------//
// Teal
// -----------------------------------------------------------------//

// -----------------------------------------------------------------//
// Purple
// -----------------------------------------------------------------//
$purple-border: darken($purple, 10%);
$purple-background: darken($purple, 10%);

.btn-purple {
  color: $white;
  background-color: $purple;
  border-color: $purple-border;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  color: $white;
  background-color: $purple-background;
  border-color: $purple-border;
}
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  background-image: none;
}
.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
  background-color: $purple;
  border-color: $purple-border;
}
.btn-purple .badge {
  color: $purple;
  background-color: $white;
}
// -----------------------------------------------------------------//
// Warning 2
// -----------------------------------------------------------------//
$warning-2: #d87620;
$warning-2-dark: darken($warning-2, 10%);
.btn-warning-2 {
  color: $white;
  background-color: $warning-2;
  border-color: $warning-2-dark;
}
.btn-warning-2:hover,
.btn-warning-2:focus,
.btn-warning-2:active,
.btn-warning-2.active,
.open .dropdown-toggle.btn-warning-2 {
  color: $white;
  background-color: $warning-2-dark;
  border-color: $warning-2-dark;
}
.btn-warning-2:active,
.btn-warning-2.active,
.open .dropdown-toggle.btn-warning-2 {
  background-image: none;
}
.btn-warning-2.disabled,
.btn-warning-2[disabled],
fieldset[disabled] .btn-warning-2,
.btn-warning-2.disabled:hover,
.btn-warning-2[disabled]:hover,
fieldset[disabled] .btn-warning-2:hover,
.btn-warning-2.disabled:focus,
.btn-warning-2[disabled]:focus,
fieldset[disabled] .btn-warning-2:focus,
.btn-warning-2.disabled:active,
.btn-warning-2[disabled]:active,
fieldset[disabled] .btn-warning-2:active,
.btn-warning-2.disabled.active,
.btn-warning-2[disabled].active,
fieldset[disabled] .btn-warning-2.active {
  background-color: $warning-2;
  border-color: $warning-2-dark;
}
.btn-warning-2 .badge {
  color: #fff;
  background-color: $warning-2;
}
// -----------------------------------------------------------------//
// ---------- End warning-2 btn ---------- //
// -----------------------------------------------------------------//
.color-line {
  position: fixed;
  width: 100%;
  z-index: 1024;
  background: #f7f9fa;
  height: 3px;
  background-image: -webkit-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -moz-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -ms-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: linear-gradient(
    to right,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
// Individual custom classes and ids
.navbar-brand {
  background-size: 175px auto !important;
}
.btn-submit-app {
  color: #fff;
  height: 41px;
}
#btn-bulk-submit {
  margin-top: 10px;
}
.store-search-app-icon {
  height: 35px;
}
.fa-android {
  color: $android;
}
.fa-apple {
  color: $ios;
}

.purple-icon {
  color: $purple !important;
}
.black-icon {
  color: $black !important;
}
.danger-icon {
  color: $red !important;
}
.critical-icon {
  color: maroon;
}
.warning-icon {
  color: $yellow !important;
}
.info-icon {
  color: $gray-900 !important;
}
.success-icon {
  color: $green !important;
}
.primary-icon {
  color: $quokka-primary !important;
}
.threat-score-badge {
  border-radius: 5px;
  font-size: 100%;
  color: #fff;
  padding: 0.5em 0.5em 0.55em 0.5em;
}
.btn-html-report {
  background-color: #e34c26;
  margin: 2px;
}
.btn-pdf-report {
  background-color: #c8101f;
  margin: 2px;
}
.btn-json-results {
  // background-color: #1b84c1;
  background-color: $blue;
  margin: 2px;
  color: #fff;
}
.btn-traffic-results {
  // background-color: #1b84c1;
  background-color: $orange;
  margin: 2px;
  color: #fff;
}
.btn-resubmit-app {
  // background-color: #1b84c1;
  background-color: $green;
  margin: 2px;
  color: #fff;
}

.resubmit-flash {
  animation: color-flash 2s infinite;
  -webkit-animation: color-flash 2s infinite;
}

@keyframes color-flash {
  0% {
    background-color: $green;
  }
  50% {
    background-color: #e3e3e3;
  }
  100% {
    background-color: $green;
  }
}

@-webkit-keyframes color-flash {
  0% {
    background-color: $green;
  }
  50% {
    background-color: #e3e3e3;
  }
  100% {
    background-color: $green;
  }
}

.btn-remove-app {
  background-color: $red;
}
.btn-reassign-app {
  background-color: $purple;
}
.btn-html-report,
.btn-pdf-report,
.btn-remove-app i {
  color: #fff;
}
.no-padding {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.report-btn-header {
  font-weight: bold;
}
.platform-radio-option {
  cursor: pointer;
}
#btn-refresh-analyzed-apps {
  cursor: pointer;
}
#pagination-info {
  text-align: center;
  margin-bottom: 10px;
}
#login-header-row {
  position: fixed;
  top: 3px;
  text-align: center;
  background-color: #fff;
  width: calc(
    100% - 200px
  ); /* Adjust width according to the left and right offsets */
  left: 50%; /* Move to the horizontal center of the viewport */
  transform: translateX(-50%); /* Adjust the position to center the element */
}
.input-group-warning {
  background-color: $orange;
  color: $white;
}
.input-group-warning:hover {
  background-color: darken($orange, 10%);
  color: darken($white, 10%);
}
.full-width-header {
  width: 100%;
}
.main {
  margin-top: 20px;
}
.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}
.bg-purple {
  background-color: $purple;
}
.bg-warning-2 {
  background-color: $warning-2;
}
.height-100 {
  height: 100%;
}
.aside-bottom {
  height: 100vh;
}
.portal-help-row {
  height: 80vh;
}
.documentation-card {
  height: 40vh;
}
.no-underline {
  text-decoration: none;
}
.threat-item-enabled-btn,
.threat-item-weight-btn {
  line-height: 1.2;
}
// Toastr font awesome integration
#toast-container > .toast {
  background-image: none !important;
}
#toast-container > .toast:before {
  position: relative;
  font-family: 'Font Awesome 5 Pro';
  font-size: 24px;
  line-height: 18px;
  float: left;
  color: #fff;
  padding-right: 0.5em;
  margin-right: 0.5em;
  margin-top: 0.25em;
}
#toast-container > .toast-warning:before {
  content: '\f071';
}
#toast-container > .toast-error:before {
  content: '\f057';
}
#toast-container > .toast-info:before {
  content: '\f05a';
}
#toast-container > .toast-success:before {
  content: '\f058';
}
#toast-container > .toast-warning-v2:before {
  content: none;
}
#toast-container > .toast-error-v2:before {
  content: none;
}
#toast-container > .toast-info-v2:before {
  content: none;
}
#toast-container > .toast-success-v2:before {
  content: none;
}

.forgotten-password-text {
  font-style: italic;
  color: rgb(21, 97, 168);
  cursor: pointer;
  margin-top: 10px;
  float: right;
  &:hover {
    color: black;
  }
}

.bold {
  font-weight: bold;
}
.break-word {
  word-break: break-word;
}

.bold-line {
  border: none;
  height: 1px;
  background: #9c9c9c;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: none;
}
.recharts-tooltip-wrapper {
  z-index: 9999999;
}
.no-link {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.nav-link {
  cursor: pointer;
}

.fa-file-pdf {
  color: #c8101f !important;
}

.fa-html5 {
  color: #e34c26 !important;
}

.font-lg {
  font-size: 0.95rem;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.secondary-sidebar {
  border: 1px solid $gray-200;
  border-radius: 3px;
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.banner-logo {
  content: url('../img/Quokka_Banner_V2.svg');
  max-height: 7.5rem;
}

.toggledown-primary {
  border-radius: 5px;
  border-color: rgb(21, 97, 168);
  background-color: #fff;
  color: rgb(21, 97, 168);
}

.button-toggledown-primary {
  color: rgb(21, 97, 168);
  padding: 0;
  border: none;
  background: none;
}

@media (forced-colors: active) {
  .progress-bar.bg-danger {
    background: CanvasText !important;
  }
}

// Sidebar
.sidebar {
  font-weight: 600;
}

.fixed-table {
  table-layout: fixed;
}

.wrap-text {
  overflow-wrap: break-word;
}

.app-icon {
  height: 30px;
  width: 100%;
  object-fit: contain;
}

.code {
  color: '#bd4147';
  background-color: '#f1f3f8';
}

.analyzed-apps-reports-actions {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1500px) {
  .analyzed-apps-reports-actions {
    flex-direction: column;
  }
}

// Toasts
.toast-warning-v2 {
  background: none;
  padding-left: 0 !important;
  width: unset !important;
  max-width: 640px;
}
.toast-error-v2 {
  background: none;
  padding-left: 0 !important;
  width: unset !important;
  max-width: 640px;
}
.toast-info-v2 {
  background: none;
  padding-left: 0 !important;
  width: unset !important;
  max-width: 640px;
}
.toast-success-v2 {
  background: none;
  padding-left: 0 !important;
  width: unset !important;
  max-width: 640px;
}
