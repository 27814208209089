.card-accent-warning-2 {
  border-top-width: 2px;
  border-top-color: $warning-2;
}
.card-accent-android-ios {
  border-top-width: 2px;
  border-top-color: #a5d11c;
}
.card-accent-teal {
  border-top-width: 2px;
  border-top-color: $teal;
}
.card-accent-purple {
  border-top-width: 2px;
  border-top-color: $purple;
}
.card-accent-pink {
  border-top-width: 2px;
  border-top-color: $pink;
}
.card-accent-navy {
  border-top-width: 2px;
  border-top-color: $navy;
}

.critical-card-shadow {
  box-shadow: 0 1px 3px rgba($maroon, 0.76), 0 1px 3px rgba($maroon, 0.86);
}
.high-card-shadow {
  box-shadow: 0 1px 3px rgba($red, 0.76), 0 1px 3px rgba($red, 0.86);
}
.medium-card-shadow {
  box-shadow: 0 1px 3px rgba($orange, 0.76), 0 1px 3px rgba($orange, 0.86);
}
