// core overrides

$border-color: $gray-200;

// Navbar

$navbar-height: 60px;
$navbar-brand-width: 220px;

// Sidebar

$sidebar-width: 220px;
$mobile-sidebar-width: 200px;

// Sidebar Navigation

$sidebar-nav-color: #fff;
$sidebar-nav-title-padding-y: 1rem;
$sidebar-nav-title-padding-x: 1.25rem;
$sidebar-nav-link-padding-y: 1rem;
$sidebar-nav-link-padding-x: 1.25rem;

// Aside

$aside-menu-nav-padding-y: 1rem;
$aside-menu-nav-padding-x: 1.25rem;
